import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import emailjs from '@emailjs/browser';
import '../css/common.css';
import '../css/registerus.css'


function OemInquirys() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [localPhoneNumber, setLocalPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const [targetCountry, setTargetCountry] = useState('');
  const [productType, setProductType] = useState('');
  const [volume, setVolume] = useState('');
  const [quantity, setQuantity] = useState('');
  const [features, setFeatures] = useState('');

  const [loading, setLoading] = useState(false);

  function formatTimestamp(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      
      if (!firstName || !lastName || !countryCode || !localPhoneNumber || !email || !targetCountry || !productType || !quantity) {
        alert("Please enter required data.");
        setLoading(false);
        return;
      }

      try {

        const today = new Date().toISOString().split('T')[0];

        const timestamp = formatTimestamp(new Date());
  
        await addDoc(collection(db, "inquirys"), {
          firstName: firstName,
          lastName: lastName,
          countryCode: countryCode,
          localPhoneNumber: localPhoneNumber,
          email: email,

          targetCountry: targetCountry,
          productType: productType,
          volume: volume,
          quantity: quantity,
          features: features,

          createdAt: serverTimestamp(),
          timestamp:timestamp

        });

        const templateParams = {
          firstName,
          lastName,
          countryCode,
          localPhoneNumber,
          email,
          targetCountry,
          productType,
          volume,
          quantity,
          features,
          timestamp
      };
      const emailResult = await emailjs.send('service_e6fxwyo', 'template_9jbh0se', templateParams, '-PYXUln711WX82nnA');
      console.log(emailResult.text);

      alert('Registration Successful.\nDarlibaba Manager will contact you ASAP.');
      navigate('/');
      } catch (error) {
        alert('Invalid Info');
        console.log(error.message);
      }finally {
        setLoading(false);
      }
    };
      
  return (
    <>
    {loading && (
        <div className="loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
)}

<div className='inquirybanner'>
    <div  style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}}>Inquiry</div>
</div>
<div style={{minHeight:"800px", display:"flex", backgroundColor:"#f4f4f4"}}>
<div className="register-us-container">

          <div className="register-us-section">
        <h2 className="register-us-section-title">User Info</h2>
        <form className="register-us-form" onSubmit={handleSubmit}>
          <div className="register-us-form-group">
            <label>First Name<span style={{color:"red"}}>*</span></label>
            <input 
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter first name" />
          </div>
          <div className="register-us-form-group">
            <label>Last Name<span style={{color:"red"}}>*</span></label>
            <input 
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter last name" />
          </div>
          <div className="register-us-form-group">
            <label>Phone Number<span style={{color:"red"}}>*</span></label>
            <div className='phone-area' style={{margin:"0px 0px"}}>
              <PhoneInput
                country={'us'}
                value={countryCode}
                onChange={(value, country) => setCountryCode(`+${country.dialCode}`)}
                placeholder="Select country code"
              />
              <input
                className='phone'
                type="tel"
                style={{borderRadius: "0px 5px 5px 0px"}}
                value={localPhoneNumber}
                onChange={(e) => setLocalPhoneNumber(e.target.value)}
                placeholder="Enter local phone number"
              />
              </div>
          </div>
          <div className="register-us-form-group">
            <label>Email<span style={{color:"red"}}>*</span></label>
            <input 
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email" />
          </div>
        </form>
      </div>

      <div className="register-us-section">
        <h2 className="register-us-section-title">Product Info</h2>
        <form className="register-us-form">
          <div className="register-us-form-group">
            <label>Target Country<span style={{color:"red"}}>*</span></label>
            <input 
                type="text"
                value={targetCountry}
                onChange={(e) => setTargetCountry(e.target.value)}
                placeholder="Enter target country" />
          </div>
          <div className="register-us-form-group">
            <label>Product Type<span style={{color:"red"}}>*</span></label>
            <input 
                type="text"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                placeholder="ex. skincare, serum.." />
          </div>
          <div className="register-us-form-group">
            <label>Volume</label>
            <input 
                type="text"
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
                placeholder="ex. g, ml" />
          </div>
          <div className="register-us-form-group">
            <label>Quantity<span style={{color:"red"}}>*</span></label>
            <input 
                type="text"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                placeholder="How many units do you need?" />
          </div>
          <div className="register-us-form-group">
            <label>Other Features</label>
            <input 
                type="text"
                value={features}
                onChange={(e) => setFeatures(e.target.value)}
                placeholder="Describe other features" />
          </div>
        </form>
      </div>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button className="register-us-submit-button" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
    </div>
    
   </>
  );
}

export default OemInquirys;
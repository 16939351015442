import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth } from './firebase';
import axios from 'axios';

import Main from './page/Main';
import Mypage from './page/Mypage';
import OemInquiry from './page/OemInquiry';
import Aboutus from './page/Aboutus';

import './css/common.css'
import Navigations from './common/Navigations';
import Footer from './common/Footer';
import ScrollToTop from './common/ScrollToTop';
import Portfolio from './page/Portfolio';
import Process from './page/Process';
import Term from './page/Term';
import Personal from './page/Personal';
import OemInquirys from './page/OemInquirys';
import OurProduct from './page/OurProduct';
import ContactUs from './page/ContactUs';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userObj, setUserObj] = useState(null);

  const phoneNumber = process.env.REACT_APP_WHATSAPP_PHONE;

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        if (user) {
          setIsLoggedIn(true);
          setUserObj(user);
        } else {
          setIsLoggedIn(false);
          setUserObj(null);
        }
      });
      return unsubscribe;
    }, []);

    const handleWhatsAppClick = () => {
      // Firebase Function URL로 새 탭에서 리디렉션하여 정보유출 방지
      const firebaseFunctionUrl = 'http://43.202.29.32:5050/redirectToWhatsApp';
      window.open(firebaseFunctionUrl, '_blank');
  };

  return (
    <Router>
      <ScrollToTop />
      <div>
      <Navigations />
      {isLoggedIn ? (
        <Routes>
          <Route path="/" element={<Main isLoggedIn={isLoggedIn}/>} />
          <Route path="/mypage" element={<Mypage />} />
          <Route path="/oeminquiry" element={<OemInquiry isLoggedIn={isLoggedIn} userObj={userObj}/>} />
          <Route path="/ourproduct" element={<OurProduct />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/process" element={<Process />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/term" element={<Term />} />
          <Route path="/personal" element={<Personal />} />
        </Routes>
      ) : (
        <Routes>
        <Route path="/" element={<Main isLoggedIn={isLoggedIn}/>} />
        <Route path="/oeminquirys" element={<OemInquirys isLoggedIn={isLoggedIn} />} />
        <Route path="/ourproduct" element={<OurProduct />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/process" element={<Process />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/term" element={<Term />} />
        <Route path="/personal" element={<Personal />} />
      </Routes>
      )}
      <div onClick={handleWhatsAppClick} style={{position:'fixed', zIndex:"2"}}><div class="whatsapp"></div></div>
      <Footer />
      </div>
    </Router>
  );
}

export default App;
import React, { useState, useEffect, useRef } from 'react';
import darli01 from '../../asset/darli01.jpg'
import darli02 from '../../asset/darli02.jpg'
import darli03 from '../../asset/darli03.jpg'
import darli04 from '../../asset/darli04.jpg'
import darli05 from '../../asset/darli05.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../../css/partner.css';

function Partner() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);

    const totalSlides = 5;

    const nextSlide = () => {
        if (currentIndex < totalSlides - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    useEffect(() => {
        const slideWidth = sliderRef.current ? sliderRef.current.children[0].clientWidth : 0;
        sliderRef.current.style.transform = `translateX(-${currentIndex * slideWidth}px)`;
    }, [currentIndex]);

    return (
        <section className='partner'>
            <div className='main4'>
                <div className='main4-heading text-center mb-8'>
                    <h1 className='text-3xl font-bold text-gray-800'>OUR INSTAGRAM</h1>
                </div>

                <div className='relative w-full overflow-hidden'>
                    <div ref={sliderRef} className='flex transition-transform duration-700 ease-in-out'>
                        <div className='flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2 cursor-pointer'>
                            <a href="https://www.instagram.com/p/DBIyBExpDMF/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
                                <LazyLoadImage
                                    src={darli01}
                                    className="w-full h-auto rounded-lg image-card transform transition-transform duration-300 hover:scale-110"
                                    alt="Slide 1"
                                    effect="blur"
                                />
                            </a>
                        </div>
                        <div className='flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2 cursor-pointer'>
                            <a href="https://www.instagram.com/p/DBaNmbZSV7d/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
                                <LazyLoadImage
                                    src={darli02}
                                    className="w-full h-auto rounded-lg image-card transform transition-transform duration-300 hover:scale-110"
                                    alt="Slide 2"
                                    effect="blur"
                                />
                            </a>
                        </div>
                        <div className='flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2 cursor-pointer'>
                            <a href="https://www.instagram.com/p/DA-iTsbJNhY/?utm_source=ig_web_copy_link" target="_blank" rel="noopener noreferrer">
                                <LazyLoadImage
                                    src={darli03}
                                    className="w-full h-auto rounded-lg image-card transform transition-transform duration-300 hover:scale-110"
                                    alt="Slide 3"
                                    effect="blur"
                                />
                            </a>
                        </div>
                        <div className='flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2 cursor-pointer'>
                            <a href="https://www.instagram.com/p/DBQhbDeJ4q5/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
                                <LazyLoadImage
                                    src={darli04}
                                    className="w-full h-auto rounded-lg image-card transform transition-transform duration-300 hover:scale-110"
                                    alt="Slide 4"
                                    effect="blur"
                                />
                            </a>
                        </div>
                        <div className='flex-shrink-0 w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2 cursor-pointer'>
                            <a href="https://www.instagram.com/p/DA2jrPgph7o/?utm_source=ig_web_copy_link" target="_blank" rel="noopener noreferrer">
                                <LazyLoadImage
                                    src={darli05}
                                    className="w-full h-auto rounded-lg image-card transform transition-transform duration-300 hover:scale-110"
                                    alt="Slide 5"
                                    effect="blur"
                                />
                            </a>
                        </div>
                    </div>

                    <button onClick={prevSlide} className='absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-3 py-2'>
                        ‹
                    </button>
                    <button onClick={nextSlide} className='absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-3 py-2'>
                        ›
                    </button>
                </div>
            </div>
        </section>
    );
}

export default Partner;
import React, { useState, useEffect } from 'react';
import '../css/common.css';
import '../css/contactus.css';
import contact from '../asset/contact.png';

function ContactUs() {
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const handleMapLoad = () => {
    setIsMapLoaded(true);
  };

  const handleWhatsAppClick = () => {
    // Firebase Function URL로 새 탭에서 리디렉션 시켜서 정보유출 방지
    const firebaseFunctionUrl = 'https://us-central1-darlibaba-10ad0.cloudfunctions.net/redirectToWhatsApp';
    window.open(firebaseFunctionUrl, '_blank');
};

  return (
    <>
      <div className='contactusbanner'>
        <div style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}} >Contact Us</div>
      </div>

      <div style={{border: "1px solid black", height: "900px", display: "flex"}}>
      <div style={{ width: "100%", maxWidth: "1400px", margin: "auto", height: "100%", position: "relative", padding: "20px" }}>
          
                <div style={{ textAlign: "center", padding: "10px" }}>
                <h1 style={{ fontSize: "2rem", fontWeight: "bold", margin: "10px 0" }}>BIGPIE C&T</h1>
                </div>
      
            <div style={{ textAlign: "center", marginTop: "20px" }}>
                <p style={{ fontSize: "1.1rem", color: "#333" }}>
                <strong>Number:</strong> +82-70-4034-6904
                </p>
                <p style={{ fontSize: "1.1rem", color: "#333" }}>
                <strong>Email:</strong> bigpie_global@jungdari.com
                </p>
                <p style={{ fontSize: "1.1rem", color: "#333" }}>
                <strong>Address:</strong> 122, jomaru-ro 385 beon-gil Bucheon-si, Gyeonggi-do, Republic of Korea
                </p>
                <img
                style={{
                  margin: "20px auto",
                  width: "200px",
                    cursor: "pointer",
                }}
                onClick={handleWhatsAppClick}
                src={contact}
                />
            </div>

            <div style={{ marginTop: "20px", position: "relative", height: "450px" }}>

                {!isMapLoaded && (
                <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#f4f4f4",
                    borderRadius: "8px",
                    zIndex: 1
                }}>
                    <div style={{
                    width: "50px",
                    height: "50px",
                    border: "5px solid #ddd",
                    borderTop: "5px solid #2575fc",
                    borderRadius: "50%",
                    animation: "spins 1s linear infinite"
                    }}></div>
                </div>
                )}

                <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.2708559053835!2d126.78911497721185!3d37.50152942785104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b63dc1ade6441%3A0x76e94c87cfc8871f!2z7IK867O07YWM7YGs64W47YOA7JuM!5e0!3m2!1sko!2skr!4v1731265015703!5m2!1sko!2skr&hl=en&region=US" 
                width="100%" 
                height="450" 
                style={{ border: "0", borderRadius: "8px" }} 
                onLoad={handleMapLoad}
                allowFullScreen 
                referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>

        </div>
      </div>
    </>
  );
}

export default ContactUs;
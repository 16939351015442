import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/oemlist.css';

function OemList() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = 3;

  useEffect(() => {
    const items = document.querySelectorAll('.carousel-item');

    function setSlide(prevIndex, nextIndex) {
      items[prevIndex].classList.remove('active');
      items[nextIndex].classList.add('active');
    }

    const moveRight = () => {
      const prevIndex = currentIndex;
      const newIndex = (currentIndex + 1) % totalItems;
      setSlide(prevIndex, newIndex);
      setCurrentIndex(newIndex);
    };

    const moveLeft = () => {
      const prevIndex = currentIndex;
      const newIndex = (currentIndex - 1 + totalItems) % totalItems;
      setSlide(prevIndex, newIndex);
      setCurrentIndex(newIndex);
    };

    const rightButton = document.getElementById('moveRight');
    const leftButton = document.getElementById('moveLeft');

    rightButton.addEventListener('click', moveRight);
    leftButton.addEventListener('click', moveLeft);

    items[currentIndex].classList.add('active');

    return () => {
      rightButton.removeEventListener('click', moveRight);
      leftButton.removeEventListener('click', moveLeft);
    };
  }, [currentIndex, totalItems]);

  return (
    <section className='main2'>

      <div className='main2-heading'>
          <h1><strong>OEM/ODM PORTFOLIO</strong></h1>
      </div>

      <div className="carousel">
        <div className="carousel__nav">
          <span id="moveLeft" className="carousel__arrow">
            <svg className="carousel__icon" width="24" height="24" viewBox="0 0 24 24">
              <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
            </svg>
          </span>
          <span id="moveRight" className="carousel__arrow">
            <svg className="carousel__icon" width="24" height="24" viewBox="0 0 24 24">
              <path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"></path>
            </svg>
          </span>
        </div>
        <div className="carousel-item carousel-item--1">
          <div className="carousel-item__info">
            <h2 className="carousel-item__subtitle">SKIN CARE</h2>
            <h1 className="carousel-item__title">5 in 1 Brigtening Serum</h1>
            <p className="carousel-item__description">A 5 in 1 serum that has effects on whitening and<br/>wrinkle improvement while covering skin imperfections</p>
          </div>
          <div className="carousel-item__image"></div>
        </div>
        <div className="carousel-item carousel-item--2">
          <div className="carousel-item__info">
            <h2 className="carousel-item__subtitle">SKIN CARE</h2>
            <h1 className="carousel-item__title">Artemisia Essential Facial Cleanser</h1>
            <p className="carousel-item__description">Pack cleanser with a sticky and creamy formulation<br/>that can give a cleanser effect with a soft pack and dense foam</p>
          </div>
          <div className="carousel-item__image"></div>
        </div>
        <div className="carousel-item carousel-item--3">
          <div className="carousel-item__info">
            <h2 className="carousel-item__subtitle">SUN CARE</h2>
            <h1 className="carousel-item__title">ALOE SUN CREAM</h1>
            <p className="carousel-item__description">A sunscreen that applies smoothly to the skin, providing not only UV protection but also the soothing effect of aloe</p>
          </div>
          <div className="carousel-item__image"></div>
        </div>
      </div>
    </section>
  );
}

export default OemList;
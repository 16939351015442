import React from 'react';
import videobg from '../../asset/bigp.mp4';
import videobgmo from '../../asset/bgv.mp4';
import { Link } from 'react-router-dom';

function Video({isLoggedIn}) {
    return(
        <>
        <section className="main">
         <div className="video-container">
        <video className='video' autoPlay loop muted poster="#">
            <source src={videobg} type='video/mp4' />
            <img class="alterbg" src="#" alt="Alternative Image"/>
        </video>
        <div className="overlay"></div>
        </div>

       </section>

       <section className="main-mo">
         <div className="video-container">
        <video className='video' autoPlay loop muted poster="#">
            <source src={videobgmo} type='video/mp4' />
            <img class="alterbg" src="#" alt="Alternative Image"/>
        </video>
        <div className="overlay"></div>
        </div>
        

           <h2>Let me make your dream</h2>
           <h1>COME TRUE</h1>
           {isLoggedIn ? (
            <Link to="oeminquiry"><button>Click It!</button></Link>
           ) : (
           <Link to="oeminquirys"><button>Click It!</button></Link>
           )}


       </section>

       </>
    );
}

export default Video;
